// @flow

import React from 'react';
import Scrollspy from 'react-scrollspy';
import styled from 'styled-components';
import type {StyledComponent} from 'styled-components';

import {mediaQueries, themeColors} from 'theme';
import {rhythm} from 'utils/typography';

const StyledNav = styled.nav`
  display: block;
  margin: ${rhythm(2 / 5)} 0 0;
  padding-left: ${rhythm(1)};
`;

const SubpagesIndex = styled(Scrollspy)`
  display: block;
  list-style: none;
  padding: 0;
  margin: 0 !important;

  .innerNavItem {
    margin: 0 0 ${rhythm(1 / 3)};
    transform: translateX(1px);

    ${mediaQueries.MIN_MD} {
      line-height: 0.75;
    }
  }

  .innerNavItem a {
    color: ${themeColors.textSubtle};
    font-size: 14px;
    font-weight: 500;
    transition: all 0.25s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      color: ${themeColors.textLinkSecondary};
    }
  }

  .innerNavItem.isCurrent > a,
  .innerNavItem.isCurrent > a:hover {
    color: ${themeColors.primary};
  }

  .indent-1 {
    padding-left: 0;
  }

  .indent-2 {
    padding-left: ${rhythm(1 / 4)};
  }

  .indent-3 {
    padding-left: ${rhythm(1 / 2)};
  }
`;

const renderItems = (items) => {
  const headingIds = items.map((h) => h.id); // Create a simple array of all IDs we expect in the document

  return (
    <SubpagesIndex items={headingIds} currentClassName="isCurrent">
      {items.map((item) => {
        // TODO: Editing this value will control the depth of contents to be listed
        if (item.depth < 3) {
          return (
            <li
              className={`innerNavItem indent-${item.depth - 1}`}
              key={item.id}>
              <a href={`#${item.id}`} className="noDecoration fontSecondary">
                {item.value}
              </a>
            </li>
          );
        }
      })}
    </SubpagesIndex>
  );
};

type Props = {
  headings?: $ReadOnlyArray<{
    id: string,
    value: string,
    depth: number,
  }>,
};

const NavSinglePageContents = (
  props: Props,
): StyledComponent<null, null, StyledNav> => {
  const {headings} = props;
  const headingList = headings && headings.filter((item) => item.depth < 4); // Discard anything deeper than an H3

  return <StyledNav>{renderItems(headingList)}</StyledNav>;
};

export default NavSinglePageContents;
