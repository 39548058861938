// @flow

import React from 'react';
import styled from 'styled-components';

import {mediaQueries, themeColors} from 'theme';
import {rhythm} from 'utils/typography';

import Link from './Link';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.onlyNext ? 'flex-end' : 'space-between'};
  border-top: 1px solid ${themeColors.black20};
  margin-top: ${rhythm(1)};
  padding-top: ${rhythm(1 / 2)};

  ${mediaQueries.MIN_LG} {
    margin-top: ${rhythm(2)};
  }

  a:nth-of-type(1) {
    text-align: ${(props) => (props.onlyNext ? 'right' : 'left')};
  }
`;

const StyledLink = styled(Link)`
  * {
    color: ${themeColors.textSubtle};
  }

  &:nth-of-type(2) {
    text-align: right;
  }

  .supportingTitle {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  .chapterTitle {
    transition: all 0.35s ease-in-out;
  }

  &:hover,
  &:active,
  &:focus {
    .chapterTitle {
      color: ${themeColors.textLinkSecondary};
    }
  }
`;

type Props = {
  pages?: Object,
  onlyNext?: boolean,
};

const TITLE_PREV = 'Previous:';
const TITLE_NEXT = 'Next:';

const NavPageFooter = (props: Props) => {
  const {pages} = props;

  if (pages) {
    const onlyNext = !pages.previous;
    return (
      <Container onlyNext={onlyNext}>
        {!onlyNext && pages.previous && (
          <StyledLink className="noDecoration" to={pages.previous.url}>
            <span className="supportingTitle fontSecondary">{TITLE_PREV}</span>{' '}
            <span className="chapterTitle">{pages.previous.title}</span>
          </StyledLink>
        )}
        {pages.next && (
          <StyledLink className="noDecoration" to={pages.next.url}>
            <span className="supportingTitle fontSecondary">{TITLE_NEXT}</span>{' '}
            <span className="chapterTitle">{pages.next.title}</span>{' '}
          </StyledLink>
        )}
      </Container>
    );
  }
};

export default NavPageFooter;
