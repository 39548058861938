// @flow

import * as React from 'react';
import styled, {keyframes} from 'styled-components';

import {mediaQueries, themeColors} from 'theme';
import {rhythm} from 'utils/typography';
import {getPageUrl, isCurrentNav} from 'utils/urlFormatters';

import NavItem from './NavItem';

const contentEntrance = keyframes`
  0% { transform: translate3d(0, 5px, 0); opacity: 0.85; pointer-events: none; }
  100% { transform: translate3d(0, 0, 0); opacity: 1; pointer-events: all; }
`;

const StyledNav = styled.nav`
  .navBlock {
    position: relative;
    list-style: none;
    line-height: 1;
    margin: 0 !important;
    padding: ${rhythm(1 / 3)} 0;
    font-weight: 600;

    &:before {
      content: '';
      display: block;
      width: 1px;
      position: absolute;
      height: 100%;
      top: 0;
      left: -1px;
      bottom: 0;
      right: 0;
      background: ${themeColors.black20};
    }

    &.currentPage:before {
      background: linear-gradient(
        to bottom,
        ${themeColors.black20} 20%,
        ${themeColors.black100} 20%,
        ${themeColors.black100} 50%,
        ${themeColors.black20} 100%
      );

      ${mediaQueries.MIN_LG} {
        background: linear-gradient(
          to bottom,
          ${themeColors.black20} 10%,
          ${themeColors.black100} 10%,
          ${themeColors.black100} 50%,
          ${themeColors.black20} 100%
        );
      }
    }

    &:nth-of-type(1) {
      padding-top: 0;

      &:before {
        margin-top: 0.5rem;
        height: 1.25rem;
        background: ${themeColors.black20};
      }
    }

    &:nth-of-type(1),
    &:nth-of-type(8),
    &:nth-of-type(9) {
      .animatedLinks {
        display: none;
      }
    }

    &:nth-of-type(8) {
      &:before {
        height: 2.25rem;
        background: ${themeColors.black20};
      }
    }

    &:nth-of-type(9) {
      padding-bottom: 0;

      &:before {
        height: 1.125rem;
        background: ${themeColors.black20};
      }
    }
  }

  .currentPage .animatedLinks {
    animation-name: ${contentEntrance};
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.2, 1, 0.4, 1);
    animation-fill-mode: forwards;
  }

  ${mediaQueries.MAX_MD} {
    .animatedLinks {
      display: none;
    }
  }
`;

type Props = {
  allPages: $ReadOnlyArray<{
    id: string,
    order?: Number,
    summary?: string,
    title?: string,
    url: string,
  }>,
  currentPage: string,
  contents?: React.Component<*, *>,
};

const NavAllPages = (props: Props) => {
  const {currentPage, allPages, contents} = props;
  const currentPageSlugified = getPageUrl(currentPage);

  return (
    <StyledNav data-testid="sidebarNav">
      {allPages.map((page, index) => {
        const isCurrentPage = isCurrentNav(
          page.url,
          currentPageSlugified,
          true,
        );

        return (
          <li
            className={`navBlock ${isCurrentPage ? 'currentPage' : ''}`}
            key={page.id}>
            <NavItem
              data-testid="sidebarLink"
              key={page.id}
              slug={page.url}
              currentPath={currentPageSlugified}
              text={page.title}
              requireExactMatch
              pageIndex={index + 1}
            />
            {isCurrentPage && contents && (
              <div className="animatedLinks">{contents}</div>
            )}
          </li>
        );
      })}
    </StyledNav>
  );
};

export default NavAllPages;
