// @flow

import React from 'react';
import styled, {keyframes} from 'styled-components';

import {mediaQueries, themeColors} from 'theme';
import {getPageUrl, isCurrentNav} from 'utils/urlFormatters';

import Link from './Link';

const circleEntrance = keyframes`
  0% { transform: translate3d(0, 2px, 0); opacity: 0.85; }
  100% { transform: translate3d(0, 0, 0); opacity: 1; }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 400;
  color: ${themeColors.textLinkSecondary};
  transition: all 0.35s ease-in-out;
  font-size: 24px !important;

  &:before {
    content: '';
    width: 5px;
    height: 5px;
    background: ${themeColors.textLinkSecondary};
    margin-right: 32px;
    border-radius: 100%;
    position: relative;
    top: 1px;
    left: -3px;
    transition: all 0.25s ease-in-out;
  }

  .linkContent {
    display: flex;
    position: relative;
  }

  .chapterNumber {
    position: absolute;
    left: -54px;
    top: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 31px;
    background: ${themeColors.backgroundSidebar};
    border: 1px solid ${themeColors.textLinkSecondary};
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    opacity: 0;

    span {
      color: ${themeColors.textLinkSecondary};

      ${mediaQueries.MIN_LG} {
        .navBlock:nth-of-type(10) & {
          transform: translateY(1px);
        }
      }
    }
  }

  &:hover,
  &:active,
  &:focus {
    color: ${themeColors.textLinkSecondary};
  }

  &.current {
    color: ${themeColors.textLinkSecondary};
    font-weight: 600;

    .chapterNumber {
      animation-name: ${circleEntrance};
      animation-duration: 0.5s;
      animation-timing-function: cubic-bezier(0.2, 1, 0.4, 1);
      animation-fill-mode: forwards;
    }
  }
`;

type Props = {
  currentPath: string,
  slug: string,
  text: string,
  requireExactMatch?: boolean,
  pageIndex: number,
};

const NavItem = (props: Props): React$Element<React$FragmentType> => {
  const {currentPath, slug, text, requireExactMatch, pageIndex} = props;

  const linkDestination = getPageUrl(slug);
  const isCurrent = isCurrentNav(
    linkDestination,
    currentPath,
    requireExactMatch,
  );

  return (
    <StyledLink
      data-testid={slug}
      to={linkDestination}
      className={['noDecoration', 'navItem', isCurrent && 'current'].join(' ')}
      title={`Navigate to ${text}`}>
      <div className="linkContent">
        <span className="chapterNumber fontSecondary">
          <span>{pageIndex}</span>
        </span>
        <span className="chapterTitle">{text}</span>
      </div>
    </StyledLink>
  );
};

export default NavItem;
