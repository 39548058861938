// @flow

import {graphql} from 'gatsby';
import React from 'react';

import Content from 'components/Content';
import Header from 'components/Header';
import Layout from 'components/Layout';
import NavAllPages from 'components/NavAllPages';
import NavPageFooter from 'components/NavPageFooter';
import NavSinglePageContents from 'components/NavSinglePageContents';
import {getPageNavigation} from 'utils/pageNavigators';
// import strings from '../config/strings.json';

type Props = {
  data: Object,
  location: Object,
  pageContext: Object,
};

const PageText = (props: Props): React$Element<typeof Layout> => {
  const {data, location, pageContext} = props;
  const {frontmatter, html, parent, headings} = data.markdownRemark;
  const {title, description} = frontmatter;

  const currentPath = location.pathname;
  const neighbouringPages = getPageNavigation(
    pageContext.sitePages,
    currentPath,
  );
  // const {chapterSubheader} = strings.pages['book'];
  const metaTitle = title ? title : parent.name;

  return (
    <Layout
      path={currentPath}
      pageMeta={{title: metaTitle, description: description}}
      sidebar={
        <NavAllPages
          allPages={pageContext.sitePages}
          currentPage={currentPath}
          contents={<NavSinglePageContents headings={headings} />}
        />
      }>
      {/* {!hideChapterNumber && (
        <span
          data-testid="chapterSubheader"
          className="fontSecondary blockcaps"
          style={{
            display: 'block',
            marginBottom: '0.5rem',
            fontSize: '14px',
            fontWeight: '500',
          }}>
          {`${chapterSubheader} ${neighbouringPages.current.chapter}`}
        </span>
      )} */}
      <Header title={metaTitle} />
      <Content html={html} />
      <NavPageFooter pages={neighbouringPages} />
    </Layout>
  );
};

export default PageText;

export const pageQuery: any = graphql`
  query($id: String!) {
    markdownRemark(id: {eq: $id}) {
      html
      frontmatter {
        title
      }
      parent {
        ... on File {
          name
        }
      }
      headings {
        id
        value
        depth
      }
    }
  }
`;
